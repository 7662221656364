import React from 'react'
import useSafariDesktopDetection from '../utils/useSafariDesktopDetection'

const BrowserDetect = () => {

  const isSafariDesktop = useSafariDesktopDetection()

  return (
    <div style={{ color: 'white', backgroundColor: 'black' }}>
      <h2>Is Safari Desktop?</h2>
      <h3>Result: {isSafariDesktop ? 'true' : 'false'}</h3>
    </div>
  )
}

export default BrowserDetect
